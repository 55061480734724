import i18next from 'i18next';

import {
  PayoutRequisitesAutomationStatus,
  PayoutRequisitesAutomationType,
} from 'enums';

const getTypeLabel = (type: PayoutRequisitesAutomationType) =>
  i18next.t(`payout_requisites_automation_type.${type}`, {
    defaultValue: type,
  });

const getStatusLabel = (status: PayoutRequisitesAutomationStatus) =>
  i18next.t(
    `features.requisites.payout_requisites_automation.status.${status}`,
    {
      defaultValue: status,
    },
  );

export const payoutRequisitesAutomationUtils = {
  getTypeLabel,
  getStatusLabel,
};
