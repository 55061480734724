import axios from 'axios';

import { env } from 'config';
import { BalanceReconciliation } from 'types';

import { createCrudApi } from './crud.api';

const path = '/balance-reconciliation';
const url = `${env.apiUrl}${path}`;

const refresh = async (ignoreArchived: boolean) =>
  (
    await axios.post(`${url}/refresh`, undefined, {
      params: { ignoreArchived },
    })
  ).data;

export const balanceReconciliationApi = {
  refresh,
  ...createCrudApi<BalanceReconciliation>(path),
};
