import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { balanceReconciliationApi } from 'api';
import {
  CrudPage,
  DataGridColumnDefinition,
  StylizedMoney,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { useCurrencies, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { BalanceReconciliation } from 'types';

import { IgnoreArchivedIcon } from '../IgnoreArchivedIcon';

export const PlatformTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.balance_reconciliation',
  });

  const { role } = useUser();

  const { getDefaultAssetCurrency } = useCurrencies();

  const defaultAssetCurrencyId = useMemo(
    () => getDefaultAssetCurrency()?.id,
    [getDefaultAssetCurrency],
  );

  const queryResult = usePartialQuery(
    QueryKey.BalanceReconciliationPlatform,
    balanceReconciliationApi.getAllPaginatedAsRole(role),
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<BalanceReconciliation>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.assets_balance'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.assetsBalance}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.wallets_balance'),
        valueGetter: (item) => (
          <div className="tw-flex tw-gap-1">
            <StylizedMoney
              value={item.walletsBalance}
              assetCurrencyId={defaultAssetCurrencyId}
              symbol
            />
            <IgnoreArchivedIcon ignoreArchived={item.ignoreArchived} />
          </div>
        ),
      },
      {
        header: t('fields.providers_balance'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.providersBalance}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.providers_not_completed_transactions'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.providersNotCompletedTransactions}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
        valueClassName: 'tw-w-[200px]',
      },
      {
        header: t('fields.difference'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.difference}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
            valueClassName={
              item.difference < 0
                ? 'tw-text-red-500'
                : item.difference > 0
                ? 'tw-text-green-500'
                : undefined
            }
          />
        ),
      },
    ],
    [t, defaultAssetCurrencyId],
  );

  return (
    <CrudPage
      table={{ queryResult, columns, paginated: true }}
      filters={{ filtersDefinitions: [], withCommon: true }}
    />
  );
};
