import {
  LayersClearOutlined as LayersClearOutlinedIcon,
  LayersOutlined as LayersOutlinedIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

type Props = {
  ignoreArchived: boolean;
};

export const IgnoreArchivedIcon: React.FC<Props> = ({ ignoreArchived }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.balance_reconciliation',
  });

  return (
    <Tooltip
      title={t(
        `ignore_archived_icon.${
          ignoreArchived ? 'exclude_archived' : 'include_archived'
        }`,
      )}
    >
      {ignoreArchived ? (
        <LayersClearOutlinedIcon fontSize="small" color="disabled" />
      ) : (
        <LayersOutlinedIcon fontSize="small" color="disabled" />
      )}
    </Tooltip>
  );
};
