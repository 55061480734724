import {
  Sync as SyncIcon,
  SmartToyOutlined as SmartToyOutlinedIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { filter, find, includes, isEmpty } from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions, useQuery, useQueryClient } from 'react-query';

import { payoutRequisitesAutomationApi, usersApi } from 'api';
import {
  DataGridColumnDefinition,
  dataGridColumns,
  RequisitesInfo,
  CrudPage,
  CopyTextId,
  CrudTableActionType,
  StatusToggle,
  ConfirmButton,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import {
  FilterDefinitionType,
  PayoutRequisitesAutomationStatus,
  PayoutRequisitesAutomationType,
  QueryKey,
} from 'enums';
import { BANKS_WITH_PAYMENT_LIMITS } from 'features/common-limits/constants';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, PayoutRequisitesAutomation } from 'types';
import {
  formatUtils,
  formUtils,
  payoutRequisitesAutomationUtils,
  requisitesUtils,
} from 'utils';

import { PayoutAutomationAccountInfoDetails } from './AccountInfoDetails';
import { PayoutLimitsInfoDialog } from '../PayoutLimitsInfoDialog';

type Filters = Partial<{
  id: string;
  type: PayoutRequisitesAutomationType;
  userId: string;
  status: PayoutRequisitesAutomationStatus;
  bankId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
}>;

type Props = {
  archived?: boolean;
};

export const PayoutRequisitesAutomationList: FC<Props> = ({ archived }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation',
  });
  const { t: tCommon } = useTranslation();
  const queryClient = useQueryClient();

  const { role, isTrader, isManager, isAdminOrTechOperator } = useUser();
  const { banks, paymentTypes } = useUserContext();

  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const detailsPath = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.PAYOUT_REQUISITES_AUTOMATION_DETAILS;
    }
    return '';
  }, [isTrader]);

  const [itemWithAccountInfos, setItemWithAccountInfos] = useState<{
    [id: string]: PayoutRequisitesAutomation;
  }>({});
  const [accountInfoLoading, setAccountInfoLoading] = useState<string[]>([]);

  const [limitsInfoDialogProps, setLimitsInfoDialogOpen] = useState<{
    open: boolean;
    requisitesAutomation?: PayoutRequisitesAutomation;
  }>({ open: false });

  const { mutate: getAccountInfo } = useMutation(
    payoutRequisitesAutomationApi.getAccountInfo,
    {
      onMutate: (itemId: string) => {
        setAccountInfoLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setItemWithAccountInfos((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setAccountInfoLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'execute',
    },
  );

  const refreshAccountInfoMutation = useMutation(
    payoutRequisitesAutomationApi.refreshAccountInfo,
    {
      onSuccess: () => {
        queryResult.refetch();
        setItemWithAccountInfos({});
      },
      notifierType: 'execute',
    },
  );

  const isAccountInfoLoading = useMemo(
    () => !isEmpty(accountInfoLoading) || refreshAccountInfoMutation.isLoading,
    [accountInfoLoading, refreshAccountInfoMutation.isLoading],
  );

  const queryResult = usePartialQuery(
    QueryKey.PayoutRequisitesAutomation,
    archived
      ? payoutRequisitesAutomationApi.getAllArchivePaginated
      : payoutRequisitesAutomationApi.getAllPaginated,
  );

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
    {
      enabled: isManager,
    },
  );

  const { mutate: archive } = useMutation(
    payoutRequisitesAutomationApi.archive,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.PayoutRequisitesAutomation);
      },
      notifierType: 'remove',
    },
  );

  const { mutate: updateStatus } = useMutation<
    PayoutRequisitesAutomation,
    AxiosError,
    { id: string; data: Pick<PayoutRequisitesAutomation, 'status'> }
  >(payoutRequisitesAutomationApi.updateAsRole(role), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.PayoutRequisitesAutomation);
    },
    notifierType: 'execute',
  });

  const handleStatusChange = useCallback(
    (
      id: string,
      status: PayoutRequisitesAutomationStatus,
      options?: MutateOptions<any, any, any, any>,
    ) =>
      updateStatus(
        {
          id,
          data: { status },
        },
        options,
      ),
    [updateStatus],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutRequisitesAutomation>[] => [
      {
        header: t('table.columns.status'),
        valueGetter: (item) => (
          <div className="tw-flex tw-items-center">
            <StatusToggle
              status={item.status}
              activeStatus={
                item.status === PayoutRequisitesAutomationStatus.Automation
                  ? PayoutRequisitesAutomationStatus.Automation
                  : PayoutRequisitesAutomationStatus.Active
              }
              inactiveStatus={PayoutRequisitesAutomationStatus.Inactive}
              disabled={!isTrader || item.requisites.archived}
              updateStatus={(status) => handleStatusChange(item.id, status)}
            />
            {item.status === PayoutRequisitesAutomationStatus.Automation && (
              <Tooltip title={t('status.automation')}>
                <SmartToyOutlinedIcon color="success" sx={{ ml: 1 }} />
              </Tooltip>
            )}
            {item.status === PayoutRequisitesAutomationStatus.Active && (
              <Tooltip title={t('status.active')}>
                <PersonIcon color="disabled" sx={{ ml: 1 }} />
              </Tooltip>
            )}
          </div>
        ),
        hidden: archived,
      },
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.trader'),
        valueGetter: (item) => item.user?.name,
        hidden: !isManager,
      },
      {
        header: t('table.columns.type'),
        valueKey: 'type',
        valueFormatter: payoutRequisitesAutomationUtils.getTypeLabel,
      },
      {
        header: t('table.columns.name'),
        valueGetter: (item) => item.name,
      },
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => (
          <div>
            <RequisitesInfo
              paymentTypeId={item.requisites?.paymentTypeId}
              bankId={item.requisites?.bankId}
              fiatCurrencyId={item.requisites?.fiatCurrencyId}
              showLimitsInfoButton={
                !archived &&
                (isAdminOrTechOperator || isTrader) &&
                includes(
                  BANKS_WITH_PAYMENT_LIMITS,
                  find(banks, (bank) => bank.id === item.requisites?.bankId)
                    ?.code,
                )
              }
              onLimitsInfoButtonClick={() =>
                setLimitsInfoDialogOpen({
                  open: true,
                  requisitesAutomation: item,
                })
              }
            />
            {formatUtils.getArchivedLabel(item.requisites)}
            <div className="tw-mt-1">
              <CopyTextId id={item.requisitesId} />
            </div>
          </div>
        ),
      },
      {
        header: t('table.columns.wallet'),
        valueGetter: (item) => item.wallet,
      },
      {
        header: (
          <div>
            {t('table.columns.account_info')}
            {!archived && (
              <ConfirmButton
                disabled={isAccountInfoLoading}
                onConfirm={() => refreshAccountInfoMutation.mutate()}
              >
                {({ onClick }) => (
                  <IconButton
                    color="primary"
                    disabled={isAccountInfoLoading}
                    onClick={onClick}
                  >
                    <SyncIcon />
                  </IconButton>
                )}
              </ConfirmButton>
            )}
          </div>
        ),
        valueClassName: archived ? undefined : 'tw-min-w-[160px]',
        valueGetter: (item) => {
          if (accountInfoLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <PayoutAutomationAccountInfoDetails
              automation={itemWithAccountInfos[item.id] || item}
              disabled={isAccountInfoLoading}
              showWarnings
              getAccountInfo={() => getAccountInfo(item.id)}
            />
          );
        },
        hidden: !(isAdminOrTechOperator || isTrader),
      },
      {
        header: t('table.columns.limit_sum'),
        valueGetter: (item) => formatUtils.formatNumber(item.limits?.limitSum),
      },
      {
        header: t('table.columns.limit_count'),
        valueGetter: (item) =>
          formatUtils.formatNumber(item.limits?.limitCount),
      },
    ],
    [
      t,
      isManager,
      isAdminOrTechOperator,
      isTrader,
      accountInfoLoading,
      itemWithAccountInfos,
      handleStatusChange,
      getAccountInfo,
      archived,
      refreshAccountInfoMutation,
      isAccountInfoLoading,
      banks,
    ],
  );

  const filtersDefinitions: FilterDefinition<Filters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.trader'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultTraders.data,
        getDisplayName: (id: string) =>
          find(queryResultTraders.data, { id })?.name,
        hidden: !isManager,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: PayoutRequisitesAutomationStatus,
        getDisplayName: payoutRequisitesAutomationUtils.getStatusLabel,
        hidden: archived,
      },
      {
        label: t('filters.type'),
        name: 'type',
        type: FilterDefinitionType.Enum,
        enum: PayoutRequisitesAutomationType,
        getDisplayName: payoutRequisitesAutomationUtils.getTypeLabel,
      },
      {
        label: t('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
      },
      {
        label: t('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (paymentType: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: paymentType })!,
          ),
      },
      {
        label: t('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      isManager,
      paymentTypes,
      queryResultTraders.data,
      t,
      tCommon,
      archived,
    ],
  );

  return (
    <Fragment>
      <CrudPage
        filters={{ filtersDefinitions }}
        table={{
          queryResult,
          columns,
          paginated: true,
          hideActions: !isTrader,
          actions: [
            {
              type: CrudTableActionType.Details,
              path: detailsPath,
            },
            {
              type: CrudTableActionType.Remove,
              onRemove: (item, { close }) =>
                archive(item.id, { onSuccess: close }),
            },
          ],
        }}
      />
      {!archived && (isAdminOrTechOperator || isTrader) && (
        <PayoutLimitsInfoDialog
          {...limitsInfoDialogProps}
          onClose={() => setLimitsInfoDialogOpen({ open: false })}
        />
      )}
    </Fragment>
  );
};
