import { Tooltip } from '@mui/material';
import { find, isEmpty, isNumber, map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { usersApi, usersBalanceReconciliationApi } from 'api';
import {
  CrudPage,
  DataGridColumnDefinition,
  StylizedMoney,
  dataGridColumns,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useCurrencies, useMoney, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, UserBalanceReconciliation } from 'types';

import { IgnoreArchivedIcon } from '../IgnoreArchivedIcon';

type UsersFilters = {
  userId: string;
};

export const UsersTab: React.FC = () => {
  const { t: tFields } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.balance_reconciliation.fields',
  });
  const { t: tСommon } = useTranslation();

  const { getFormattedMoney } = useMoney();
  const { role } = useUser();

  const { getDefaultAssetCurrency } = useCurrencies();

  const queryResultUsers = useQuery(QueryKey.Users, usersApi.getAssetHolders);

  const defaultAssetCurrencyId = useMemo(
    () => getDefaultAssetCurrency()?.id,
    [getDefaultAssetCurrency],
  );

  const queryResult = usePartialQuery(
    QueryKey.BalanceReconciliationUsers,
    usersBalanceReconciliationApi.getAllPaginatedAsRole(role),
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<UserBalanceReconciliation>[] => [
      dataGridColumns.getIdColumn({ dateKeyOverride: 'date' }),
      {
        header: tFields('user'),
        valueGetter: (item) => item.user?.name,
      },
      {
        header: tFields('assets_balance'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.assetsBalance}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: tFields('wallets_balance'),
        valueGetter: (item) => (
          <div className="tw-flex tw-gap-1">
            <Tooltip
              title={
                <table>
                  {isEmpty(item.walletsInfo)
                    ? tFields('no_wallets')
                    : map(item.walletsInfo, (info) => (
                        <tr key={info.address}>
                          <td>{`${info.address}:`}</td>
                          <td key={info.address}>
                            {isNumber(info.balance)
                              ? getFormattedMoney({ value: info.balance })
                              : tСommon('common.error')}
                          </td>
                        </tr>
                      ))}
                </table>
              }
            >
              <div>
                <StylizedMoney
                  value={item.walletsBalance}
                  assetCurrencyId={defaultAssetCurrencyId}
                  symbol
                />
              </div>
            </Tooltip>
            <IgnoreArchivedIcon ignoreArchived={item.ignoreArchived} />
          </div>
        ),
      },
    ],
    [tFields, tСommon, getFormattedMoney, defaultAssetCurrencyId],
  );

  const filtersDefinitions: FilterDefinition<UsersFilters>[] = useMemo(
    () => [
      {
        label: tFields('user'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultUsers.data,
        getDisplayName: (userId: string) =>
          find(queryResultUsers.data, { id: userId })?.name,
      },
      {
        label: tFields('assets_balance'),
        type: FilterDefinitionType.Range,
        minName: 'minAssetsBalance',
        maxName: 'maxAssetsBalance',
      },
      {
        label: tFields('wallets_balance'),
        type: FilterDefinitionType.Range,
        minName: 'minWalletsBalance',
        maxName: 'maxWalletsBalance',
      },
    ],
    [tFields, queryResultUsers.data],
  );

  return (
    <CrudPage
      table={{ queryResult, columns, paginated: true }}
      filters={{ filtersDefinitions }}
    />
  );
};
